import { createRouter, createWebHistory } from 'vue-router'

const AppLayout = () => import('/@src/layouts/AppLayout.vue')
const AuthLayout = () => import('/@src/layouts/AuthLayout.vue')
const LandingLayout = () => import('/@src/layouts/LandingLayout.vue')
const ConversationCreateLayout = () => import('/@src/layouts/ConversationCreateLayout.vue')

const routes = [
  {
    path: '/',
    component: AuthLayout,
    children: [
      {
        path: '',
        name: 'auth-login',
        component: () => import('/src/pages/auth/login.vue'),
      },
      {
        path: '',
        redirect: '/',
      },
    ],
  },
  {
    path: '/conversation',
    component: ConversationCreateLayout,
    children: [
      {
        path: 'create',
        name: 'conversation-create',
        component: () => import('/src/pages/workspace/create.vue'),
      },
    ],
  },
  {
    path: '/conversation',
    component: AppLayout,
    children: [
      {
        path: ':slug',
        name: 'conversation',
        component: () => import('/src/pages/workspace/[slug].vue'),
      },
      {
        path: '',
        redirect: '/',
      },
    ],
  },
  {
    path: '/existing',
    component: LandingLayout,
    children: [
      {
        path: '',
        name: 'existing-page',
        component: () => import('/src/pages/Existing.vue'),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)',
    component: LandingLayout,
    children: [
      {
        path: '',
        name: 'not-found',
        component: () => import('/src/pages/404.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
