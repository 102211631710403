import { createApp } from 'vue'
import Toast from 'vue-toastification'
import VueScrollTo from 'vue-scrollto'
import moment from 'moment'
import { createPinia } from 'pinia'
import 'lightgallery.js'
import router from './router'
import App from './App.vue'
import dayjs from 'dayjs'
import 'dayjs/locale/en-gb'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import * as Sentry from "@sentry/vue";
import {captureConsoleIntegration} from "@sentry/integrations";
import 'lightgallery.js/dist/css/lightgallery.css'
import 'vue-toastification/dist/index.css'
import '/src/assets/scss/main.scss'

const app = createApp(App)

Sentry.init({
  app,
  dsn: "https://9bc9bdb27d225179ef433bfac15f6504@o4504806779322368.ingest.sentry.io/4506227296567296",
  integrations: [
    captureConsoleIntegration()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost:8888", "https://whatsapp.universe.nomadasexperience.com/"],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

app.provide('dayJS', dayjs) // provide dayJS
app.provide("moment", moment);

dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
const userTimezone = dayjs.tz.guess();
// console.log(userTimezone)
dayjs.tz.setDefault(userTimezone)

app.use(router)
app.use(VueScrollTo)
app.use(Toast)
app.use(createPinia())
app.use(moment)
app.mount('#app')
